/*!; base ;*/
body {
  color: $color-grey;
  font-size: $fontsize-base;
  font-weight: normal;
  line-height: 174%;
  font-family: $font-primary;
  @include base-indent;
}

h1{
  color: $color-primary;
  font: bold 87px/110% $font-primary;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.08em;

  @include respond(m3){
    font-size: 39px;
  }
}

h2{
  color: #3C3B3A;
  font: bold 56px/125% $font-second;
  padding-bottom: 15px;

  @include respond(m2){
    font-size: 48px;
  }
  @include respond(m3){
    font-size: 39px;
  }
}

h3 {
  color: $color-primary;
  font: bold 48px/120% $font-primary;
  padding-bottom: 15px;

  @include respond(m3){
    font-size: 37px;
  }

  span{
    font-style: italic;
    font-weight: normal;
    font-family: $font-second;
  }
}

h4{
  color: #333333;
  font: bold 18px/175% $font-primary;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  padding-bottom: 10px;

  @include respond(m3){
    font-size: 18px;
  }
}

h5{
  color: #333333;
  font: bold 15px/120% $font-primary;
  padding-bottom: 13px;
  text-transform: uppercase;
  letter-spacing: 0.18em;
}

a {
  color: $color-grey;
  outline: none;
  text-decoration: none;
  transition: all .3s ease;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    border-bottom-color: $color-primary;
  }
}

p{
  padding-bottom: 15px;
}

ul{
  padding-bottom: 19px;

  li{
    position: relative;
    padding: 0 0 0 15px;
  }
}

img{
  display: block;
}

section,
.section{
  padding: 46px 0;

  @include respond(s2){
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @include respond(m3){
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.b-row{
  @include flex;
}

.reset_shad{
  -webkit-appearance: none;
  border-radius: 0;
}


///// buttons /////

.btn{
  @extend .reset_shad;
  background: none;
  border: 2px solid $color-primary;
  @include transition;
  @include display_inline_block;
  height: 53px;
  font: bold 14px/57px $font-primary;
  text-transform: uppercase;
  padding: 0 38px;
  letter-spacing: 0.15em;
  white-space: nowrap;
  color: $color-primary;
  text-align: center;
  cursor: pointer;

  &:hover{
    border: 2px solid $color-primary;
    background: $color-primary;
    color: $color-black;
  }
}

.btn_light{
  color: #727272;
  border-color: #AFAFAF;
  padding: 0 48px;

  &:hover{
    border-color: $color-black;
    color: $color-black;
  }
}

.btn_sm{
  color: #333333;
  background: $color-primary;
  border-color: $color-primary;
  height: 48px;
  line-height: 50px;
  font-size: 13px;
  padding: 0 26px;
  letter-spacing: 0.08em;
  box-sizing: border-box;

  @include respond(m3){
    height: 35px;
    line-height: 35px;
    font-size: 13px;
    padding: 0 12px;
  }

  &:hover{
    background: transparent;
    color: $color-primary;
  }
}

.btn_request{
  background: #21282F;
  border: 2px solid #AFAFAF;
  color: $color-primary;

  @include respond(m2){
    padding: 0 15px;
  }
  @include respond(m3){
    padding: 0 38px;
    font-size: 14px;
    height: 55px;
    line-height: 57px;
  }

  &:hover{
    background: $color-primary;
    color: #21282F;
    border-color: $color-primary;
  }
}

.btn_transp{
  height: 46px;
  line-height: 50px;
  font-size: 13px;
  padding: 0 28px;

  @include respond(m3){
    height: 35px;
    line-height: 38px;
    padding: 0 12px;
  }
}


///// ---buttons--- /////

.b-input{
  @extend .reset_shad;
  border-radius: 0;
  width: 100%;
  border: 0;
  padding: 0 1px;
  color: #D81524;
  font: normal 18px $font-primary;
  height: 30px;
  background: none;
  -webkit-appearance: none;

  &:focus{
    border-bottom: 1px solid #6A6A6A;
  }

  &.has-danger{
    border-bottom: 1px solid red;
  }
}

.b-textarea{
  @extend .b-input;
  padding: 0 1px;
}

.has-danger{
  border-color: red !important;
}
.thankyou-url {
  display: none;
}

.b-wrapper{
  overflow: hidden;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 320px;

  &.b-wrapper_external{
    height: 100%;
  }
}

.b-wrap{
  width: 100%;
  max-width: 1222px;
  margin: 0 auto;
  position: relative;
}

::-webkit-input-placeholder {color:#B2B2B2;}
::-moz-placeholder          {color:#B2B2B2;}
:-moz-placeholder           {color:#B2B2B2;}
:-ms-input-placeholder      {color:#B2B2B2;}

.b-ask__input::-webkit-input-placeholder {color:#ABABAB;}
.b-ask__input::-moz-placeholder          {color:#ABABAB;}
.b-ask__input:-moz-placeholder           {color:#ABABAB;}
.b-ask__input:-ms-input-placeholder      {color:#ABABAB;}

:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder          {color: transparent}
:focus:-moz-placeholder           {color: transparent}
:focus:-ms-input-placeholder      {color: transparent}