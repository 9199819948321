/*!; footer ;*/
.b-footer {
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  background: #2F2D2D;
  border-top: 2px solid $color-primary;
}

.b-footer__top{
  padding: 34px 194px 0;
  @include flex;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @include respond(s1){
    padding-left: 40px;
    padding-right: 40px;
  }
  @include respond(m2){
    padding-left: 20px;
    padding-right: 20px;
  }
  @include respond(m3){
    flex-wrap: wrap;
    padding-top: 27px;
  }
}

.b-footer__col{
  text-align: center;
  margin-right: 171px;
  padding-bottom: 20px;

  @include respond(s5){
    margin-right: 100px;
  }
  @include respond(m1){
    margin-right: 50px;
  }

  &:first-child{
    margin-right: 210px;
    width: 305px;
    padding-bottom: 0;
    position: relative;
    margin-bottom: -50px;

    @include respond(s5){
      margin-right: 100px;
    }
    @include respond(m1){
      margin-right: 50px;
    }
    @include respond(m2){
      width: 200px;
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    @include respond(m3){
      width: 100%;
      order: 3;
      margin-right: 0;
      margin-bottom: -50px;
      padding-top: 22px;
    }
  }

  &:nth-child(3){
    margin-right: 166px;

    @include respond(s5){
      margin-right: 100px;
    }
    @include respond(m1){
      margin-right: 50px;
    }
    @include respond(m2){
      margin-right: 30px;
    }
    @include respond(m3){
      margin-right: 0;
    }
  }

  h5{
    color: $color-primary;
  }

  .b-logo{
    width: 305px;
    margin-top: 22px;
    height: auto;
    position: relative;

    @include respond(m2){
      width: 200px;
    }
    @include respond(m3){
      left: 0;
      top: 0;
      margin: 0 auto 9px;
      width: 305px;
    }
    @include respond(m5){
      width: 100%;
    }

    img{
      max-width: 305px;
      max-height: 115px;

      @include respond(m2){
        max-width: 200px;
      }
      @include respond(m3){
        max-width: 305px;
        width: 305px;
      }
      @include respond(m5){
        max-width: 90%;
        width: 90%;
      }
    }
  }
}

.b-footer__socials {
  @include flex;
  justify-content: center;
  width: 100%;
  padding: 43px 0 17px;

  @include respond(m3){
    position: relative;
    top: 102px;
    padding: 0;
  }

  a{
    margin: 0 17px;
    border-bottom: 0px;

    @include respond(m2){
      margin: 0 6px;
    }
    @include respond(m3){
      margin: 0 18px;
    }

    &:before{
      @include transition;
    }

    &:hover{
      &:before{
        color: $color-primary;
      }
    }
  }
}

.b-footer__col_links{
  text-align: left;

  @include respond(m3){
    order: 1;
    width: 50%;
    margin-right: 0;
    padding-left: 43px;
  }
  @include respond(m5){
    padding-left: 0;
  }

  a{
    display: block;
    color: #AFAFAF;
    font-size: 13px;
    border: 0px;
    line-height: 100%;
    margin-bottom: 6px;

    &.bord{
      position: relative;
      color: $color-primary;
      padding-left: 8px;

      &:before{
        @include before;
        background: $color-primary;
        width: 12px;
        height: 1px;
        top: 5px;
        left: -4px;
      }

      &:hover{
        &:before{
          background-color: transparent;
        }
      }
    }

    &:hover{
      color: $color-primary;
    }
  }
}

.b-footer__col_addr{
  text-align: left;

  @include respond(m3){
    order: 2;
    width: 50%;
    padding-left: 38px;
  }
  @include respond(m5){
    padding-left: 20px;
  }

  address,
  a{
    font-style: normal;
    display: block;
    max-width: 124px;
    color: #AFAFAF;
    border: 0px;
  }

  address{
    margin-bottom: 50px;
    margin-top: 11px;

    @include respond(m3){
      margin-bottom: 19px;
    }
  }

  a{
    &:hover{
      color: $color-primary;
    }

    &.tel{
      color: $color-primary;

      &:hover{
        color: #AFAFAF;
      }
    }
  }
}

.b-footer__logo{
  padding-top: 13px;

  @include respond(s1){
    margin-left: auto;
  }
  @include respond(m3){
    order: 0;
    width: 100%;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 17px;
  }

  img{
    max-width: 167px;

    @include respond(m2){
      max-width: 120px;
    }
    @include respond(m3){
      margin: 0 auto;
      max-width: 167px;
    }
  }
}

.b-footer__bottom{
  background: #1D1D1D;
  border-top: 1px solid $color-primary;
  text-align: center;
  padding: 22px 0 18px;

  @include respond(m2){
    padding-left: 20px;
    padding-right: 20px;
    text-align: right;
  }
  @include respond(m3){
    padding-top: 100px;
    text-align: center;
  }

  >span{
    color: #AFAFAF;
    position: relative;
    left: 258px;
    display: block;

    @include respond(m2){
      left: auto;
    }

    >span{
      color: #777777;
    }
  }
}