/*!; content ;*/
.b-main{
  display: block;
  flex: 1 0 auto;
  padding-top: 136px;

  @include respond(m3){
    padding-top: 114px;
  }
  @include respond(m5){
    padding-top: 80px;
  }
}

.b-slider-wrap{
  position: relative;
  border-bottom: 2px solid $color-primary;

  @include respond(m2){
    border-bottom: 1px solid $color-primary;
  }

  .b-slider{
    .slick-arrow{
      @include respond(m5){
        display: none !important;
      }
    }
  }
}

.loading{
  background: url("../../img/loading.svg") no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -42px;
  margin-top: -42px;
  height: 84px;
  width: 84px;
}

.b-slider {
  opacity: 0;
  overflow: hidden;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;

    > div {
      display: flex;
      margin-bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  &.large {
    min-height: 883px;

    h1{
      @include respond(m3){
        padding-bottom: 5px;
      }
    }

    @include respond(m2){
      min-height: 700px;
    }
    @include respond(m3){
      min-height: 270px;
    }

    .b-slider__item {
      min-height: 883px;
      height: 100%;

      @include respond(m2){
        min-height: 700px;
      }
      @include respond(m3){
        min-height: 270px;
      }
    }

    .b-slider__headline{
      position: relative;
      top: -115px;

      @include respond(m3){
        top: -43px;
      }
    }

    .slick-arrow {
      margin-top: -102px;

      @include respond(m3){
        margin-top: -55px;
      }
    }
  }

  &.default {
    min-height: 552px;

    @include respond(m3){
      min-height: 270px;
    }

    .b-slider__item {
      min-height: 552px;
      height: 100%;

      @include respond(m3){
        min-height: 270px;
      }
    }

    .slick-arrow{
      margin-top: -93px;

      @include respond(m3){
        margin-top: -13px;
      }
    }
  }

  &.small {
    min-height: 393px;

    @include respond(m3){
      min-height: 270px;
    }

    .b-slider__item {
      min-height: 393px;

      @include respond(m3){
        min-height: 270px;
      }
    }

    h1{
      @include respond(m8){
        font-size: 69px;
      }
      @include respond(m3){
        font-size: 40px;
      }
      @include respond(m5){
        font-size: 30px;
      }
    }
  }

  .btn_transp {
    background: rgba(0, 0, 0, .48);

    &:hover{
      background: $color-primary;
      color: $color-black;
    }
  }
}

.b-slider__headline{
  text-align: center;
  padding: 20px;

  @include respond(m3){
    padding-left: 50px;
    padding-right: 50px;
  }
  @include respond(m5){
    padding-left: 40px;
    padding-right: 40px;
  }

  .btn{
    margin-top: 26px;
  }

  h4{
    color: $color-primary;
    line-height: 100%;
    letter-spacing: 0.5em;
    padding-bottom: 10px;

    @include respond(m3){
      font-size: 10px;
    }
    @include respond(m5){
      font-size: 11px;
      line-height: 110%;
    }
  }
}

.b-slider__item{
  position: relative;
  background-position: center;
  background-size: cover;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.logo-business{
  position: relative;
  margin-top: -269px;
  margin-right: 215px;
  background: rgba(0,0,0,.57);
  height: 267px;
  width: 264px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: auto;

  @include respond(m2){
    display: none;
  }

  img{
    max-width: 222px;
  }
}

.b-services{
  justify-content: center;
  padding: 2px 0 0;
  @include flex;
  border-bottom: 2px solid $color-primary;

  @include respond(m2){
    @include flex-col;
  }
}

.b-service{
  width: 33.333%;
  text-align: center;
  border-right: 2px solid $color-primary;
  position: relative;

  @include respond(m2){
    width: 100%;
    border-right: 0px;
    border-bottom: 1px solid $color-primary;
  }
  @include respond(m3){
    height: 175px;
    overflow: hidden;
  }

  &:last-child{
    border-right: 0px;
  }

  a{
    border-bottom: 0px;
    display: block;

    &:hover{
      .b-service__content{
        margin-top: 70px;

        @include respond(m2){
          margin-top: 0;
        }
      }

      i{
        background: rgba(0,0,0,.57);
      }
    }
  }

  img{
    width: 100%;
    position: relative;

    @include respond(m3){
      margin-top: -30px;
    }
    @include respond(m5){
      margin-top: 0;
    }
  }
}

.b-service__content{
  color: $color-primary;
  display: block;
  position: absolute;
  top: 72px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 112px;
  box-sizing: border-box;
  @include transition;

  @include respond(s2){
    top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 30px;
  }
  @include respond(m2){
    display: block;
    top: 40%;
  }
  @include respond(m3){
    top: 22%;
  }

  span{
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.25em;

    @include respond(s2){
      font-size: 18px;
      margin-top: auto;
    }
    @include respond(m3){
      font-size: 16px;
    }
  }

  i{
    @include display_inline_block;
    font: italic 14px/120% $font-second;
    position: relative;
    top: 12px;
    letter-spacing: 0.07em;
    padding: 3px 5px;

    @include respond(s2){
      background: rgba(0,0,0,.57);
      margin-bottom: auto;
    }
    @include respond(m3){
      font-size: 16px;
      top: 5px;
      padding: 5px 11px;
    }
  }
}

.b-info,
.b-info-2,
.b-info-3,
.b-info-4{
  background: #F8F8F8;
  border-bottom: 1px solid #AFAFAF;
  padding-bottom: 39px;

  .btn_light{
    color: #AFAFAF;

    &:hover{
      color: $color-black;
    }
  }

  .b-row{
    @include respond(m3){
      @include flex-col;
    }
  }
}

.b-info__left{
  min-width: 50%;
  width: 50%;
  padding: 10px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;

  @include respond(m3){
    width: 100%;
    padding: 5px 15px 0 35px;
  }
  @include respond(m5){
    padding-left: 0px;
    padding-right: 0px;
  }

  .btn{
    margin-top: 26px;
    margin-right: auto;
    margin-bottom: auto;

    @include respond(m3){
      display: none;
    }
  }

  h2{
    max-width: 550px;
    margin-right: auto;
    width: 100%;
  }

  h5{
    color: #333333;
    padding-bottom: 0;
  }
}

.b-info__right{
  min-width: 50%;
  width: 50%;
  border-left: 2px solid #CCCCCC;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px 0 24px 76px;
  &.portfolio-info {
    display: block;
  }

  @include respond(m2){
    padding-left: 40px;
  }
  @include respond(m3){
    width: 100%;
    border-left: 0px;
    padding: 0 15px 0 35px;
  }
  @include respond(m5){
    padding-left: 0px;
    padding-right: 0px;
  }

  p{
    padding-bottom: 0;
  }

  .btn{
    &.mob{
      display: none;

      @include respond(m3){
        @include display_inline_block;
        margin-top: 18px;
      }
    }
  }

  h4{
    @include respond(m3){
      padding-bottom: 5px;
    }
  }
}

.b-awards{
  background: $color-primary;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 72px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @include respond(m3){
    padding-bottom: 6px !important;
  }

  h4{
    letter-spacing: 0.28em;

    @include respond(m3){
      display: none;
    }
  }
}

.b-awards__slider{
  padding: 42px 132px 0;
  position: relative;

  @include respond(m2){
    padding-left: 45px;
    padding-right: 45px;
  }
  @include respond(m3){
    padding-top: 12px;
  }
  @include respond(m5){
    padding-left: 10px;
    padding-right: 10px;
  }
}

.b-awards__item{
  @include flex;
  margin: 0 36px;
  max-width: 511px;

  @include respond(s4){
    margin: 0 auto;
  }
  @include respond(m3){
    max-width: 260px;
    margin: 0 auto;
  }

  img{
    max-width: 170px;
    margin-right: 12px;
    margin-bottom: auto;

    @include respond(m3){
      max-width: 86px;
      margin-right: 6px;
      position: relative;
      top: -7px;
    }
  }
}

.b-awards__item_2{
  @include flex-col;
  min-width: 327px;
  width: 327px;
  margin: 0 auto;

  @include respond(m3){
    min-width: 260px;
    width: 260px;
  }

  img{
    max-width: 133px;
    margin-bottom: auto;
    margin-right: 27px;
    position: relative;
    left: -9px;
    top: 4px;

    @include respond(m3){
      left: 0;
      max-width: 86px;
    }
  }

  .b-award__top{
    @include flex;
    padding-bottom: 5px;
    padding-top: 11px;

    span{
      padding-top: 9px;

      @include respond(m3){
        padding-top: 0;
      }
    }
  }

  .b-award{
    min-width: 330px;
    width: 330px;

    @include respond(m3){
      min-width: 260px;
      width: 260px;
    }
  }
}

.b-award{
  text-align: center;
  min-width: 327px;
  width: 327px;
  margin: 0 auto;

  @include respond(m3){
    width: 166px;
    min-width: 166px;
  }
}

.b-award__top{
  color: #3C3B3A;
  font: bold 18px/135% $font-second;
  padding: 27px 5px 26px;
  border-bottom: 3px solid #505050;
  margin-bottom: 4px;

  @include respond(m3){
    padding: 0 0 16px;
    font-size: 14px;
    line-height: 110%;
    border-bottom-width: 1px;
    margin-bottom: 2px;
  }

  strong{
    display: block;
    font-size: 26px;

    @include respond(m3){
      font-size: 18px;
    }
  }

  span{
    display: block;
    font-size: 20px;
    line-height: 140%;
    font-weight: normal;
    font-family: $font-primary;

    @include respond(m3){
      font-size: 14px;
    }
  }

  >div{
    text-align: center;
  }
}

.b-award__bottom{
  padding: 19px 0;
  text-transform: uppercase;
  color: #727272;
  font-size: 20px;
  line-height: 120%;
  border-top: 4px solid #505050;

  @include respond(m3){
    font-size: 11px;
    padding: 10px 0;
    border-top-width: 2px;
  }
}

.b-portfolio{
  text-align: center;
  background: #2F2D2D;
  padding: 91px 0 55px !important;

  @include respond(m3){
    padding-bottom: 35px !important;
    border-bottom: 2px solid $color-primary;
  }

  h4{
    color: #CCCCCC;
    letter-spacing: 0.3em;

    @include respond(m3){
      font-size: 13px;
      padding-bottom: 7px;
    }
  }

  h3{
    padding-top: 24px;

    @include respond(m3){
      padding-top: 0;
    }
  }
}

.b-portfolio__slider{
  margin: 0 auto;
  max-width: 1070px;
  position: relative;
  padding: 21px 0 28px;

  @include respond(m1){
    max-width: 870px;
  }
  @include respond(m2){
    max-width: 600px;
  }
  @include respond(m3){
    padding-top: 40px;
    padding-bottom: 0;
    max-width: 290px;
  }

  .slick-list{
    overflow: visible;
  }
}

.b-portfolio__item{
  max-width: 970px;
  margin: 0 auto;

  @include respond(m1){
    max-width: 870px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @include respond(m2){
    max-width: 600px;
  }
  @include respond(m3){
    padding-right: 6px;
    padding-left: 6px;
  }

  img{
    max-width: 100%;
    margin: 0 auto 55px;

    @include respond(m3){
      margin-bottom: 17px;
    }
  }

  a{
    border-bottom: 0px;

    &:hover{
      span{
        opacity: .3;
      }
    }
  }

  span{
    color: $color-primary;
    display: block;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    padding: 0 0 10px;
    @include transition;

    @include respond(m3){
      font-size: 13px;
      padding-bottom: 6px;
      line-height: 110%;
    }
  }

  i{
    display: block;
    color: #ABABAB;
    font-size: 14px;
    font-style: italic;
    font-family: $font-second;
    letter-spacing: 0.07em;

    @include respond(m3){
      font-size: 9px;
      line-height: 110%;
    }
  }
}

.bottom-link-container{
  position: relative;
  background-color: inherit;
  text-align: center;

  &:before{
    content: "";
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  a{
    position: relative;
    z-index: 5;
    color: #ABABAB;
    background-color: inherit;
    font-size: 14px;
    font-weight: bold;
    padding: 0 26px;
    letter-spacing: 0.22em;
    border-bottom: 0px;

    @include respond(m3){
      font-size: 10px;
    }

    &:hover{
      color: $color-primary;
    }
  }
}

.b-opinions{
  background: #3C3B3A;
  position: relative;
  padding: 88px 0;
  text-align: center;

  &:before{
    @include before;
    background: $color-primary;
    height: 58px;
    width: 2px;
    left: 50%;
    margin-right: -1px;
    top: 0;
  }

  h4{
    color: #CCCCCC;
    letter-spacing: 0.28em;
  }
}

.b-testimonials{
  background: #3C3B3A;
  text-align: center;
  padding: 89px 0 77px;

  h4{
    color: #CCCCCC;
    letter-spacing: 0.3em;

    @include respond(m3){
      font-size: 17px;
    }
  }
}

.b-testimonials__slider{
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
  text-align: left;
}

.testimonial {
  padding: 58px 8%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #f7f7f7;

  @include respond(m3){
    @include flex-col;
    padding: 50px 25px 0;
  }
  @include respond(m5){
    padding: 10px 0 0;
  }
}

.testimonial--left {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  margin-right: 10px;
  margin-top: 6px;

  @include respond(m3){
    width: 100%;
    margin-right: 0;
    padding-bottom: 38px;
  }

  img{
    border-radius: 100%;

    @include respond(m3){
      margin: 0 auto;
    }
  }
}

.testimonial--right{
  @include respond(m3){
    width: 100%;
  }
  p{
    padding-bottom: 40px;
    position: relative;
    font-size: 34px;
    line-height: 140%;

    @include respond(m2){
      font-size: 26px;
    }
    @include respond(m3){
      font-size: 17px;
      line-height: 185%;
      padding-bottom: 5px;
    }
  }

  .ts-author {
    font-size: 24px;
    text-align: right;
    font-weight: bold;

    @include respond(m2){
      font-size: 20px;
    }
    @include respond(m3){
      display: none;
    }
  }

  .ts-position {
    font-family: $font-second;
    font-style: italic;
    font-size: 22px;
    letter-spacing: 0.1em;

    @include respond(m2){
      font-size: 20px;
    }
    @include respond(m3){
      display: none;
    }
  }
}

.b-affiliations{
  text-align: center;
  background: $color-primary;
  padding-top: 51px;
  padding-bottom: 51px;

  @include respond(m4){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  h4{
    color: #3C3B3A;
    letter-spacing: 0.25em;
  }
}

.b-affiliations__slider{
  max-width: 1608px;
  margin: 0 auto;
  position: relative;
  padding: 29px 0 0;

  @include respond(s0){
    padding-left: 60px;
    padding-right: 60px;
  }
  @include respond(m4){
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
  }

  img{
    margin: 0 auto;

    @include respond(m4){
      max-width: 70%;
    }
  }

  .slick-track{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.b-work{
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: $color-primary;
  align-items: center;
  display: flex;
  min-height: 715px;
  position: relative;

  @include respond(m3){
    min-height: auto;
    width: 100%;
    background-position: top right;
  }

  &.line{
    padding: 30px 20px !important;

    @include respond(m3){
      padding-top: 39px !important;
    }

    &:before {
      @include before;
      background: $color-primary;
      height: 58px;
      width: 2px;
      top: 0;
      left: 50%;
      margin-left: -1px;

      @include respond(m3) {
        height: 26px;
      }
    }
  }

  .b-wrap{
    max-width: 675px;
    text-align: center;
  }

  h4{
    letter-spacing: 0.5em;
    color: $color-primary;
    padding-bottom: 28px;

    @include respond(m3){
      padding-bottom: 18px;
      line-height: 130%;
    }
  }

  h2{
    font-size: 87px;
    color: $color-primary;
    font-family: $font-primary;
    line-height: 115%;
    letter-spacing: 0.08em;
    padding-bottom: 35px;

    @include respond(m2){
      font-size: 70px;
    }
    @include respond(m3){
      font-size: 32px;
      padding-bottom: 5px;
      line-height: 100%;
    }
  }

  p{
    max-width: 617px;
    margin: 0 auto;

    @include respond(m3){
      font-size: 14px;
      line-height: 120%;
      max-width: 274px;
      margin: 0 auto;
      padding-bottom: 26px;
    }
  }
}

.b-about{
  background: $color-primary;
  padding: 68px 0;

  .b-row{
    @include respond(m2){
      @include flex-col;
    }
  }
}

.b-about__left{
  width: 50%;
  padding: 0 41px 0 0;

  @include respond(m2){
    width: 100%;
    padding: 0;
  }

  h5{
    color: #3C3B3A;
  }
}

.b-about__right{
  width: 50%;
  padding: 60px 40px 0 31px;

  @include respond(m2){
    width: 100%;
    padding: 50px 0 20px;
  }
  @include respond(m2){
    padding-top: 30px;
  }
}

.b-about__video{
  padding: 56px 0 0;

  @include respond(m1){
    padding-top: 30px;
  }

  iframe{
    border: 0px;
    width: 100%;
    height: 100%;
    min-height: 320px;

    @include respond(m4){
      min-height: 250px;
    }
  }
}

.faq{
  padding: 0 0 58px;

  &:last-child{
    padding-bottom: 0;
  }
}

.faq__question{
  font: bold 24px/120% $font-primary;
  position: relative;
  padding: 0 32px 7px 0;
  border-bottom: 2px solid #E7E7E7;
  cursor: pointer;
  color: #3C3B3A;

  @include respond(m3){
    font-size: 18px;
  }

  &.active{
    border-bottom-color: #D3C8B5;

    span{
      background: #D1C8B7;

      &:before{
        display: none;
      }
    }
  }

  span{
    position: absolute;
    top: 3px;
    right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #333333;

    &:before,
    &:after{
      @include before;
      height: 10px;
      width: 2px;
      top: 5px;
      left: 9px;
      background: $color-primary;
    }
    &:after{
      height: 2px;
      width: 10px;
      top: 9px;
      left: 5px;
    }
  }
}

.faq__answer{
  color: #7E7E7E;
  font-size: 17px;
  padding: 20px 0 0;
  display: none;
}

.line{
  position: relative;

  @include respond(m2){
    padding-top: 60px !important;
  }
  @include respond(m3){
    padding-top: 74px !important;
  }

  &:before{
    @include before;
    background: $color-primary;
    height: 58px;
    width: 2px;
    left: 50%;
    top: 0;
    margin-left: -1px;

    @include respond(m2){
      height: 40px;
    }
    @include respond(m3){
      height: 50px;
    }
  }
}

.b-about-2{
  text-align: center;
  background: #2F2D2D;
  padding: 92px 0 70px;
  color: $color-primary;

  @include respond(s2){
    padding-left: 20px;
    padding-right: 20px;
  }

  .b-row{
    justify-content: center;
    padding: 96px 0 0;

    @include respond(m3){
      flex-wrap: wrap;
      padding-top: 60px;
    }
  }

  h4{
    color: $color-primary;
    padding-bottom: 23px;
    letter-spacing: 0.25em;
  }
}

.b-num{
  width: 298px;
  padding: 0 20px;

  @include respond(m2){
    width: 25%;
  }
  @include respond(m3){
    width: 50%;
    padding-bottom: 50px;
  }

  strong{
    display: block;
    font-size: 56px;
    padding: 0 0 45px;

    @include respond(m3){
      font-size: 46px;
      padding-bottom: 20px;
    }
  }

  >span{
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.12em;

    @include respond(m3){
      font-size: 15px;
    }
  }
}

.b-about-2__txt{
  font: normal 18px/180% $font-second;
  max-width: 728px;
  margin: 0 auto;
  padding: 57px 0 0;
  color: #CCCCCC;

  @include respond(m3){
    padding-top: 0px;
  }

  strong{
    font-style: italic;
  }
}

.b-history{
  background: $color-primary;
  padding: 62px 0 25px;

  @include respond(s2){
    padding-left: 20px;
    padding-right: 20px;
  }

  .b-wrap{
    max-width: 1374px;
  }

  h2{
    padding-left: 84px;
    padding-bottom: 24px;

    @include respond(s2){
      padding-left: 0;
    }
  }

  .b-row{
    @include respond(m2) {
      @include flex-col;
    }
  }
}

.b-history__left{
  position: relative;
  z-index: 2;
  width: 52%;
  padding: 0 0 0 84px;

  @include respond(s2){
    padding-left: 0;
  }
  @include respond(m2){
    width: 100%;
    padding-bottom: 20px;
  }

  img{
    max-width: 100%;
  }
}

.b-history__right{
  width: 48%;
  position: relative;
  z-index: 1;
  padding: 47px 40px 54px 80px;
  margin: 50px 0 0;

  @include respond(s2){
    padding-left: 30px;
  }
  @include respond(m2){
    width: 100%;
    margin-top: 0;
  }
  @include respond(m3){
    padding: 25px;
  }

  &:before{
    @include before;
    border: 2px solid #E7E7E7;
    top: 0;
    right: 0;
    height: 100%;
    width: 1047px;
    box-sizing: border-box;

    @include respond(s2){
      width: 150%;
    }
    @include respond(m2){
      width: 100%;
    }
  }

  &:after{
    @include before;
    background: #F8F8F8;
    border: 1px solid #E7E7E7;
    top: 8px;
    right: 8px;
    width: 1030px;
    height: calc(100% - 18px);

    @include respond(s2){
      width: calc(150% - 18px);
    }
    @include respond(m2){
      width: calc(100% - 18px);
    }
  }
}

.b-history__txt{
  position: relative;
  z-index: 2;
  font-size: 18px;
  line-height: 180%;

  p:first-letter {
    font: 900 63px/100% $font-second !important;
  }

  img{
    margin: 10px 0 0;
  }
}

.b-philanthropy{
  background-position: top center;
  background-repeat: no-repeat;
  @include flex-col;
  justify-content: center;
  align-items: center;
  min-height: 570px;
  padding: 30px 20px;

  @include respond(m3){
    min-height: 350px;
  }

  h3{
    font-weight: normal;
    padding-bottom: 17px;
    margin-top: 110px;
    text-align: center;
    font-family: $font-second;

    @include respond(m2){
      margin-top: 0;
    }
  }
}

.b-testimonials.white_bg {
  background: $color-primary;
  padding-top: 39px;
  padding-bottom: 16px;

  @include respond(m3){
    padding-top: 30px;
  }

  .testimonial{
    padding-bottom: 0;
  }

  .testimonial--right {
    padding-right: 100px;

    @include respond(m2){
      padding-right: 0;
    }

    p {
      font-family: $font-second;
      color: #333333;
      padding-bottom: 33px;

      @include respond(m3){
        padding-bottom: 5px;
      }
    }
  }

  .ts-author {
    color: #333333;
  }

  .ts-position {
    color: #7E7E7E;
    font-size: 14px;
  }

  .slick-arrow{
    border-color: #CCCCCC;

    svg{
      path{
        stroke: #7E7E7E;
      }
    }

    &:hover{
      border-color: #727272;
      background: #727272;

      svg{
        path{
          stroke: $color-primary;
        }
      }
    }
  }
}

.link-pos{
  top: -30px;
  margin-bottom: -27px;
  position: relative;
  left: -100px;

  @include respond(s2){
    left: 0;
  }
  @include respond(m3){
    left: auto;
    text-align: center;
    top: -10px;
    margin-bottom: 0;
  }
}

.back-link{
  color: #AAAAAA;
  font-size: 12px;
  position: relative;
  text-transform: uppercase;
  border-bottom: 0px;
  letter-spacing: 0.2em;
  padding: 0 0 0 17px;

  &:hover{
    color: $color-black;
  }

  img{
    height: 12px;
    position: absolute;
    top: -2px;
    left: 0;
  }
}

.b-info-2,
.b-info-3{
  border-bottom: 0px;

  .b-info__left{
    padding-left: 16px;
    min-width: 47%;
    width: 47%;

    @include respond(m3){
      width: 100%;
    }
    @include respond(m5){
      padding-left: 0;
    }
  }

  .b-info__right{
    min-width: 53%;
    width: 53%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 96px;
    position: relative;
    right: -44px;

    @include respond(s4){
      right: 0;
      padding-left: 30px;
    }
    @include respond(m3){
      width: 100%;
      padding-left: 16px;
      padding-top: 0;
    }
    @include respond(m5){
      padding-left: 0;
    }
  }
}

.b-events{
  padding: 0 !important;

  .b-row{
    border-bottom: 3px solid $color-primary;

    @include respond(m2){
      @include flex-col;
    }

    &:last-child{
      border-bottom: 0px;
    }
  }
}

.b-events__left{
  width: 50%;
  background: #EFEFEF;
  padding: 140px 86px 140px 325px;
  position: relative;

  @include respond(s1){
    padding: 100px 50px 100px 150px;
  }
  @include respond(s3){
    padding: 50px 30px;
  }
  @include respond(m2){
    width: 100%;
    order: 1;
    padding: 30px 25px;
  }

  &:before{
    @include before;
    background: $color-primary;
    height: 2px;
    width: 52px;
    top: 71px;
    right: 0;

    @include respond(s3){
      display: none;
    }
  }

  h4{
    font-size: 20px;
    letter-spacing: 0.12em;
    padding-bottom: 32px;
    color: #2F2D2D;

    @include respond(m2){
      padding-bottom: 20px;
    }
  }

  p{
    padding-bottom: 0;
    font-size: 17px;
    color: #030303;
  }
}

.b-events__right{
  width: 50%;
  background: #EFEFEF;

  @include respond(m2){
    width: 100%;
    order: 0;
  }

  img{
    min-width: 100%;
    min-height: 100%;

    @include respond(s3){
      min-width: auto;
      min-height: auto;
      max-width: 100%;
    }
  }
}

.btn-center{
  text-align: center;
  padding: 44px 0 28px;

  .btn_light{
    padding: 0 37px;
  }
}

.b-info-3{
  padding-top: 44px;
  border-bottom: 1px solid #bcbcbc;

  .b-info__left{
    min-width: 33%;
    width: 33%;
    padding-top: 9px;
    padding-right: 30px;
    padding-left: 37px;

    @include respond(s6){
      width: 50%;
      min-width: 50%;
      padding-left: 16px;
    }
    @include respond(m3){
      padding-right: 16px;
      min-width: 100%;
      padding-top: 0;
    }
    @include respond(m5){
      padding-left: 0;
      padding-right: 0;
    }
  }

  .b-info__right{
    min-width: 67%;
    width: 67%;
    padding-left: 60px;

    @include respond(s6){
      min-width: 50%;
      width: 50%;
      padding-left: 30px;
    }
    @include respond(m3){
      padding-left: 16px;
      min-width: 100%;
      padding-bottom: 0;
    }
    @include respond(m5){
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.b-serv-list{
  justify-content: center;
  padding-top: 38px;
  padding-bottom: 85px;

  .b-row{
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 0 auto;
  }

  .btn-center{
    padding: 0;
  }
}

.b-serv{
  min-width: 323px;
  width: 323px;
  margin: 0 74px 48px;

  @include respond(s2){
    min-width: 33.333%;
    width: 33.333%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2%;
  }
  @include respond(m8){
    min-width: 50%;
    width: 50%;
  }
  @include respond(m3){
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  p{
    color: #727272;
  }
}

.b-serv__head{
  @include flex;
  align-items: center;
  min-height: 98px;

  h5{
    color: #333333;
    letter-spacing: 0.13em;
    white-space: normal;

    @include respond(m5){
      font-size: 13px;
    }
  }

  img{
    margin-right: 6px;
    position: relative;
    top: -3px;
  }
}

.b-our-work{
  padding: 0px !important;

  .b-row{

    @include respond(m8){
      @include flex-col;
    }
    &:nth-child(even){
      .b-work__img{
        order: 1;
        border-left: 2px solid $color-primary;
        border-right: 0px;

        @include respond(m8){
          order: 0;
        }
      }

      .b-work__txt{
        order: 0;
        padding: 144px 64px 99px 324px;

        @include respond(s4){
          padding: 80px 50px;
        }
        @include respond(m8){
          order: 1;
        }
        @include respond(m3){
          padding: 30px 20px;
        }

        &:before{
          left: auto;
          right: 0;
        }
      }
    }

    &:last-child{
      .b-work__img,
      .b-work__txt{
        border-bottom: 0px;
      }
    }
  }
}

.b-work__img{
  width: 50%;
  border-bottom: 2px solid $color-primary;
  border-right: 2px solid $color-primary;
  background: #2F2D2D;

  @include respond(m8){
    width: 100%;
    border-right: 0px;
  }

  img{
    min-width: 100%;
    min-height: 100%;

    @include respond(m8){
      min-height: auto;
      max-width: 100%;
    }
  }
}

.b-work__txt{
  border-bottom: 2px solid $color-primary;
  background: #2F2D2D;
  padding: 144px 300px 99px 72px;
  position: relative;
  width: 50%;
  color: #AFAFAF;

  @include respond(s4){
    padding: 80px 50px;
  }
  @include respond(m8){
    width: 100%;
  }
  @include respond(m3){
    padding: 30px 20px;
  }

  &:before{
    @include before;
    background: $color-primary;
    height: 2px;
    width: 50px;
    left: 0;
    top: 70px;

    @include respond(s4){
      top: 30px;
    }
    @include respond(m3){
      display: none;
    }
  }

  h5{
    color: $color-primary;
    font-size: 20px;
    letter-spacing: 0.13em;
    padding-bottom: 39px;

    @include respond(m3){
      padding-bottom: 20px;
      font-size: 18px;
    }
  }

  p{
    font-size: 17px;
    max-width: 545px;

    @include respond(m8){
      max-width: 100%;
    }
  }

  .btn{
    border-color: #CCCCCC;
    color: #CCCCCC;
    margin-top: 59px;
    min-width: 170px;

    @include respond(m3){
      margin-top: 20px;
    }

    &:hover{
      color: $color-black;
      border-color: $color-primary;
    }
  }
}

.nobord{
  border-bottom: 0;
}

.b-gallery{
  padding: 0px !important;
}

.b-ask{
  background: #2F2D2D;
  text-align: center;
  padding-bottom: 36px;

  h3{
    font-family: $font-second;
  }
}

.b-ask__form{
  max-width: 770px;
  margin: 0 auto;
  padding: 36px 0 0;

  @include respond(m2){
    max-width: 100%;
  }

  .btn{
    min-width: 164px;
  }
}

.b-ask__row{
  @include flex;

  @include respond(m4){
    @include flex-col;
  }
}

.b-ask__input{
  width: 48%;
  background: #222222;
  border: 0px;
  color: #ABABAB;
  font: normal 15px $font-primary;
  padding: 0 22px;
  height: 49px;
  margin: 0 0 29px;
  border-radius: 0px;
  -webkit-appearance: none;

  @include respond(m4){
    width: 100%;
    margin-bottom: 20px;
  }

  &:last-child{
    margin-left: auto;
  }

  &.has-danger{
    border: 1px solid red;
  }
}

.b-ask__textarea{
  @extend .b-ask__input;
  width: 100%;
  height: 123px;
  padding: 19px 22px;
}

.b-headline{
  background: #F8F8F8;
  border-bottom: 2px solid #d4d4d4;
  text-align: center;
  padding-bottom: 32px;

  .b-wrap{
    max-width: 964px;
  }

  h4{
    max-width: 556px;
    margin: 0 auto;
    color: #333333;
    padding-bottom: 14px;
  }

  p{
    padding-bottom: 0;
  }
}

.b-openings{
  padding-top: 28px;

  @include respond(m3){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  h2{
    font-size: 36px;
    padding-bottom: 3px;
    padding-left: 247px;

    @include respond(s1){
      padding-left: 0;
      text-align: center;
    }
    @include respond(m3){
      font-size: 30px;
    }
  }

  .b-row{
    max-width: 1226px;
    margin: 0 auto;

    @include respond(m3){
      @include flex-col;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.b-openings__item{
  padding: 40px 0;

  @include respond(m3){
    padding: 30px 0;
  }

  &.bord{
    border-top: 2px solid #d7d7d7;
    border-bottom: 2px solid #d7d7d7;
  }
}

.b-openings__title{
  margin: 0 0 0 72px;
  min-width: 292px;
  width: 292px;
  padding-right: 20px;

  @include respond(s5){
    margin-left: 0;
  }
  @include respond(m2){
    width: 250px;
    min-width: 250px;
  }
  @include respond(m3){
    width: 100%;
    min-width: 100%;
  }
}

.b-openings__details{
  color: #7E7E7E;

  p{
    padding-bottom: 25px;
    font-size: 16px;
  }

  .btn{
    min-width: 150px;
    margin-top: 3px;
  }
}

.b-news{
  padding-bottom: 32px;

  @include respond(m3){
    padding-bottom: 30px;
  }

  .b-wrap{
    max-width: 1124px;
  }

  .link-pos{
    left: 0;
    top: -25px;
    margin-bottom: -13px;

    @include respond(m3){
      top: -10px;
      margin-bottom: 10px;
    }
  }
}

.b-news__item{
  margin: 0 auto 85px;

  @include respond(m3){
    margin-bottom: 30px;
  }

  img{
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: -200px;
    z-index: 2;

    @include respond(m3){
      margin-bottom: 10px;
    }
  }
}

.b-news__wrap{
  position: relative;
  border: 2px solid #e7e7e7;
  padding: 8px;
  z-index: 1;
}

.b-news__content{
  border: 1px solid #e7e7e7;
  background: #f8f8f8;
  text-align: center;
  padding: 210px 122px 40px;

  @include respond(m2){
    padding-left: 30px;
    padding-right: 30px;
  }
  @include respond(m3){
    padding: 25px 15px;
  }

  h3{
    font-size: 34px;
    font-weight: normal;
    color: #333333;
    font-family: $font-second;
    line-height: 170%;

    @include respond(m3){
      font-size: 22px;
    }
  }

  p{
    color: #7E7E7E;
  }

  .btn_light{
    margin-top: 11px;
    min-width: 207px;
    padding-left: 20px;
    padding-right: 20px;

    @include respond(m3){
      min-width: 100px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

.b-news__item_inside{
  margin-bottom: 0;

  .b-news__content{
    padding: 388px 120px 57px 120px;

    @include respond(m2){
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 40px;
    }
    @include respond(m3){
      padding: 25px 15px;
    }
  }

  img{
    margin-bottom: -370px;

    @include respond(m3){
      margin-bottom: 20px;
    }
  }

  .btn_light{
    margin-top: 23px;
  }

  p{
    padding-bottom: 26px;
  }
}

.b-news__headline{
  text-align: center;
  max-width: 896px;
  margin: 0 auto;

  h3{
    font-size: 34px;
    font-weight: normal;
    color: #333333;
    font-family: $font-second;
    line-height: 170%;
    padding-bottom: 7px;

    @include respond(m3){
      font-size: 22px;
    }
  }

  .b-news__date{
    padding-bottom: 6px;
  }
}

.b-news__date{
  text-align: center;
  padding-bottom: 20px;

  strong{
    color: #D8B069;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    margin-right: 22px;
    letter-spacing: 0.15em;

    @include respond(m3){
      display: block;
      margin-right: 0;
    }
  }

  span{
    color: #7E7E7E;
    font: italic 14px/120% $font-second;
    position: relative;
    padding: 0 0 0 32px;
    letter-spacing: 0.07em;

    @include respond(m3){
      padding-left: 0;
    }

    &:before{
      @include before;
      background: #b5b5b5;
      top: -1px;
      left: 0;
      width: 1px;
      height: 23px;

      @include respond(m3){
        display: none;
      }
    }
  }
}

.b-news__list{
  padding-bottom: 60px;

  @include respond(m3){
    padding-bottom: 30px;
  }

  .b-news__item:last-child{
    margin-bottom: 0;
  }
}

.pagination{
  @include flex;
  position: relative;
  justify-content: center;
  width: 100%;
  padding-bottom: 34px;

  a{
    display: block;
    height: 60px;
    width: 60px;
    background: #AFAFAF;
    text-align: center;
    color: $color-primary;
    font: normal italic 23px/66px $font-primary;
    margin: 0 6px 0 7px;
    box-sizing: border-box;
    position: relative;

    @include respond(m2){
      height: 45px;
      width: 45px;
      font-size: 18px;
      line-height: 45px;
    }

    &:hover,
    &.active{
      background: #2F2D2D;
      color: $color-primary;
    }

    &.active{
      cursor: default;
    }

    &:first-child{
      margin-right: 11px;
      margin-left: 0;

      @include respond(m2) {
        margin-right: 30px;
      }
      @include respond(m4) {
        margin-right: 7px;
      }
    }
    &:last-child{
      margin-left: 11px;
      margin-right: 0;

      @include respond(m2) {
        margin-left: 30px;
      }
      @include respond(m4) {
        margin-left: 7px;
      }
    }

    &.page-arrow{
      background: none;
      width: 30px;
      height: 30px;
      margin-top: 13px;

      @include respond(m2){
        margin-top: 8px;
      }

      img{
        width: 30px;
      }

      &:last-child{
        img{
          transform: rotate(180deg);
        }
      }

      @include respond(m2) {
        height: 45px;
        width: 45px;
        top: 0;
      }
    }

    &:nth-child(n+4):nth-child(-n+9){
      @include respond(m3) {
        display: none;
      }
    }
  }

  span{
    display: none;

    @include respond(m3){
      display: block;
      font: bold 21px/100% $font-primary;
      position: relative;
      top: 13px;
      padding: 0 10px;
    }
  }
}

.b-contact{
  padding: 60px 20px;
  text-align: center;
  color: #666666;

  @include respond(m3){
    padding-top: 30px;
    padding-bottom: 20px;
  }

  h2{
    padding-bottom: 26px;
    font-size: 62px;
    font-weight: normal;
    font-family: $font-second;

    @include respond(m2){
      padding-bottom: 30px;
      font-size: 39px;
    }
  }
}

.b-contact__items{
  @include flex;
  justify-content: center;

  @include respond(m2){
    flex-wrap: wrap;
  }
}

.b-contact__item{
  border-right: 1px solid #CCCCCC;
  min-height: 101px;
  min-width: 271px;
  margin-right: 93px;
  text-align: left;
  color: #666666;
  font-size: 16px;
  padding: 7px 0 0;

  @include respond(s6){
    min-width: 23%;
    margin-right: 3%;
  }
  @include respond(m2){
    min-width: 50%;
    margin-right: 0;
    border-right: 0;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include respond(m3){
    padding-left: 0;
  }
  @include respond(m4){
    width: 100%;
    text-align: center;
    padding-right: 0;
  }

  address{
    font-style: normal;
    display: block;
    max-width: 200px;
    line-height: 140%;

    @include respond(m4){
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    }
  }

  a{
    display: block;
    color: #666666;
    margin-bottom: 5px;

    &.phone{
      color: #0D2750;
      font-size: 24px;
    }

    &:hover{
      color: $color-black;
    }

    i{
      @include display_inline_block;
      margin-right: 17px;
      position: relative;

      &:before{
        font-size: 21px;
      }
    }
  }

  h5{
    font-size: 18px;
    color: #2A2D32;
    padding-bottom: 25px;
    letter-spacing: 0em;
    text-transform: none;
    font-weight: normal;
    font-family: $font-second;
  }

  &:last-child{
    border-right: 0;
    margin-right: 0;

    @include respond(s6){
      margin-right: 0;
    }
  }
}

.b-note{
  background: #464646;
  text-align: center;
  position: relative;
  padding: 76px 20px;

  h3{
    color: $color-primary;
    font-family: $font-second;
    letter-spacing: 0.1em;
    font-size: 24px;
    font-weight: normal;
    padding-bottom: 30px;
  }
}

.b-note__form{
  position: relative;
  padding: 31px 0 0;
  max-width: 646px;
  margin: 0 auto;

  &:before{
    @include before;
    background: #CCCCCC;
    height: 2px;
    width: 30px;
    left: 50%;
    margin-left: -15px;
    top: -7px;
  }

  .form-col{
    border-bottom: 1px solid #B3B3B3;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 49px;

    &:before,
    &:after{
      background: #B3B3B3;
    }
  }

  .form-row{
    .form-col{
      width: 293px;

      @include respond(m3){
        width: 48%;
      }
      @include respond(m4){
        width: 100%;
      }

      &:last-child{
        margin-left: auto;
      }
    }
  }
  .b-input {
    height: 40px;
    &[name="captcha"] {
      margin-top: 10px;
    }
  }
  .b-input,
  .b-textarea{
    padding: 0 12px;
    font-size: 15px;
    letter-spacing: 0.15em;
    color: $color-primary;

    @include respond(m5){
      letter-spacing: 0em;
    }

    &:focus{
      border-bottom: 1px solid $color-primary;
    }
  }
  .b-textarea {
    &.repeating-lines {
      height: auto;
      line-height: 2rem;
      background-image: repeating-linear-gradient(rgba(179, 179, 179, 0), rgba(179, 179, 179, 0) 2rem, rgba(179, 179, 179, 1) 2rem, rgba(179, 179, 179, 1) calc(2rem + 1px));
    }
  }
  .form-col_message {
    margin-top: 60px;

    @include respond(m4){
      margin-top: 0;
    }
  }

  .btn{
    color: #CCCCCC;
    border-color: #CCCCCC;
    margin-top: 12px;
    min-width: 250px;
    height: 57px;

    &:hover{
      border-color: $color-primary;
      color: $color-black;
    }
  }
}

.form-row{
  @include flex;
  justify-content: space-between;

  @include respond(m3){
    flex-wrap: wrap;
  }
}

.form-col{
  border-bottom: 2px solid #6A6A6A;
  position: relative;
  margin: 0 20px 24px;

  @include respond(m3){
    margin-left: 1%;
    margin-right: 1%;
  }

  &:before,
  &:after{
    @include before;
    background: #6A6A6A;
    width: 1px;
    height: 5px;
    bottom: 0;
    left: 0;
  }

  &:after{
    left: auto;
    right: 0;
  }

  &.w1{
    width: 296px;

    @include respond(m3){
      width: 100%;
    }
  }
  &.w2{
    width: 415px;

    @include respond(m3){
      width: 100%;
    }
  }
  &.w3{
    width: 247px;

    @include respond(m3){
      width: 48%;
    }
  }
  &.w4{
    width: 96px;

    @include respond(m3){
      width: 23%;
    }
  }
}

.b-info-4{
  padding-bottom: 13px;

  .b-wrap{
    max-width: 1280px;
  }

  .b-info__left{
    min-width: 51%;
    width: 51%;
    padding-top: 0;

    @include respond(m3){
      width: 100%;
    }

    h2{
      max-width: none;
      color: #000000;
      font-size: 62px;
      font-weight: normal;
      padding-bottom: 10px;

      @include respond(m3){
        font-size: 39px;
      }
    }

    p{
      max-width: 550px;
    }
  }

  .b-info__right{
    flex-wrap: wrap;
    padding-top: 17px;
    padding-left: 94px;
    align-items: flex-start;
    margin-bottom: auto;

    @include respond(m2){
      padding-left: 35px;
    }
    @include respond(m3){
      width: 100%;
      padding-top: 0;
    }
    @include respond(m5){
      padding-left: 0;
    }

    h3{
      font-size: 29px;
      font-weight: normal;
      font-family: $font-second;
      color: #2F2D2D;
      width: 100%;
      padding-bottom: 25px;

      @include respond(m3){
        padding-bottom: 10px;
        font-size: 20px;
      }
    }

    a{
      color: #2F2D2D;
      font-family: $font-second;
      font-size: 42px;
      display: block;

      @include respond(m3){
        font-size: 26px;
      }

      &:hover{
        color: #000;
      }
    }
  }
}

.form-col_describe{
  padding-top: 16px;
  padding-bottom: 28px;

  .form-col{
    position: relative;
    margin-bottom: -2px;
  }
}

.fancybox{
  cursor: pointer;
  display: block;
}

.justified-gallery > a > img,
.justified-gallery > div > img,
.justified-gallery > a > a > img,
.justified-gallery > div > a > img{
  z-index: 2;
}

.play{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 3;
  height: 80px;
  width: 80px;

  @include respond(m3){
    margin-left: -25px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
  }

  img{
    @include respond(m3){
      width: 50px;
      height: 50px;
    }
  }
}
.application-contact-form {
  input,
  select,
  textarea {
      width: 100%;
      font-family: inherit;
  }
  textarea {
    padding: 1rem;
  }
  .q-col {
      margin-bottom: 20px;
  }
  input,
  select {
      height: 2rem;
      padding: 0 1rem;
      border: none;
      border-bottom: 1px solid #767676;
      background-color: transparent;
  }
  input[type=radio],
  input[type=checkbox] {
      height: initial;
      width: initial;
  }
  .inline-block > * {
      display: inline-block;
      &:first-child {
        padding-left: 0!important;
      }
  }
  .form-block {
    padding: 2rem;
    background-color: #f7f7f7;
    margin-bottom: 2rem;
  }
  label {
    cursor: pointer;
  }
  .input-group {
    & > * {
      display: inline-block;
    }
    @media (max-width: 990px){
      display: block !important;
      margin-bottom: 1rem;
    }
    @media (min-width: 991px){
      padding-left: 2rem;
    }
  }
  .repeater,
  div[class^="clonedpart-"]{
    margin-top: 2rem;
  }
  .captcha-inside {
    img {
      display: inline-block;
    }
  }
  button {
    border-color: #ccc;
    color: #3c3b3a;
    height: 57px;
    margin-top: 12px;
    min-width: 250px;
    border: 2px solid #fff;
    font: 700 14px/57px Josefin Sans;
    @include transition;
    border: 2px solid #3c3b3a;
    &:hover {
      color: #fff;
      background-color: #3c3b3a;
    }
  }
}