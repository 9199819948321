/*!; header ;*/
.b-head {
  z-index: 903;
  height: 136px;
  width: 100%;
  @include flex;
  justify-content: center;
  align-items: center;
  background: #2F2D2D;
  border-bottom: 1px solid $color-primary;
  padding: 0 45px 0 187px;
  position: fixed;
  top: 0;
  left: 0;

  @include respond(s0){
    padding: 0 20px;
  }
  @include respond(m3){
    height: 114px;
  }
  @include respond(m5){
    height: 80px;
  }

  >.btn{
    margin-left: auto;

    @include respond(s1){
      padding: 0 15px;
    }
    @include respond(m1){
      display: none;
    }
  }
}

.b-logo{
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
  border-bottom: 0px;

  @include respond(m3){
    left: 20px;
    position: absolute;
    top: 5px;
  }
  @include respond(m5){
    top: 0;
  }

  img{
    max-width: 243px;
    max-height: 92px;

    @include respond(s5){
      max-width: 180px;
    }
    @include respond(m1){
      width: 188px;
      max-width: 188px;
      margin: 0 auto;
    }
    @include respond(m5){
      width: 140px;
      max-width: 140px;
    }
  }
}

.b-nav{
  @include flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: relative;
  left: 27px;

  @include respond(s1){
    left: 0;
  }
  @include respond(m1){
    position: fixed;
    top: 0;
    right: -320px;
    width: 320px;
    left: auto;
    @include flex-col;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 100px 20px 50px;
    @include transition;
    background: #2F2D2D;
  }

  .btn.mob{
    display: none;

    @include respond(m1){
      @include display_inline_block;
    }
  }
}
.wbe {
  display: block;
  margin-left: 5%;
  &:hover {
    text-decoration: none;
  }
  @include respond(m1) {
    display: none;
  }
  img {
    max-height: 65px;
    width: auto;
  }

}
.b-nav__link{
  text-transform: uppercase;
  color: #CCCCCC;
  font-size: 14px;
  line-height: 130%;
  font-weight: bold;
  border-bottom: 1px solid transparent;
  margin: 0 26px;
  letter-spacing: 0.15em;
  white-space: nowrap;

  @include respond(s1){
    margin: 0 15px;
    font-size: 13px;
  }
  @include respond(m1){
    margin-bottom: 30px;
  }

  &.active,
  &:hover{
    border-bottom-color: $color-primary;
  }
}

.burger{
  display: none;

  @include respond(m1){
    display: block;
    background: rgba(255,255,255,.28);
    width: 70px;
    height: 61px;
    top: 0px;
    right: 0;
    position: absolute;
    cursor: pointer;
  }

  span,
  &:before,
  &:after{
    @include before;
    background: $color-primary;
    width: 41px;
    height: 6px;
    left: 15px;
    transition: all .3s ease;
  }
  span{
    &:first-child{
      top: 16px;
    }
  }
  &:before{
    top: 29px;
  }
  &:after{
    top: 42px;
  }
  &:hover{
    span,
    &:before,
    &:after{

    }
  }
}

.menu_opened {
  .burger {
    position: fixed;
    z-index: 1001;
    top: 17px;
    background: none;
    border: 0;

    span {
      background: none !important;
    }

    &:before,
    &:after {
      top: 22px;
      left: 9px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      span,
      &:before,
      &:after {
        background: $color-primary;
      }
    }
  }

  .b-nav{
    right: 0;
    z-index: 1001;
    box-shadow: -3px 0px 15px rgba(0,0,0,.5);
  }
}

