/*!; slick.css ;*/
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
       box-sizing: border-box;

  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus{
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  display: block;
  margin:0 auto;

  &:before,
  &:after{
    display: table;
    content: '';
  }
  &:after{
    clear: both;
  }
}

.slick-loading{
  .slick-track {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline:none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
}

.slick-prev.slick-disabled, .slick-next.slick-disabled{
  opacity: .3;
  cursor: default;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  cursor: pointer;
  transition: all .3s ease;
  height: 48px;
  width: 48px;
  z-index: 800;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid rgba(204,204,204,.5);
  border-radius: 100%;

  @include respond(m3){
    height: 26px;
    width: 26px;
    margin-top: -13px;
    border-width: 1px;
  }

  svg{
    height: 24px;
    opacity: .5;
    @include transition;

    @include respond(m3){
      height: 12px;
    }
  }

  &:not(.slick-disabled):hover{
    border: 2px solid #CCCCCC;
    background: #CCCCCC;

    @include respond(m3){
      border-width: 1px;
    }

    svg{
      opacity: 1;

      path{
        stroke: #ffffff;
      }
    }
  }

  &.slick-prev{
    left: 83px;

    @include respond(m2){
      left: 20px;
    }
    @include respond(m3){
      left: 12px;
    }
  }

  &.slick-next{
    right: 83px;

    @include respond(m3){
      right: 12px;
    }
    @include respond(m2){
      right: 20px;
    }
    @include respond(m3){
      right: 12px;
    }
  }
}

.b-awards__slider{
  .slick-arrow {
    margin-top: -17px;

    @include respond(m3){
      margin-top: -23px;
    }

    &.slick-prev{
      left: 73px;

      @include respond(m2){
        left: 0px;
      }
      @include respond(m5){
        left: -15px;
      }
    }
    &.slick-next{
      right: 73px;

      @include respond(m2){
        right: 0px;
      }
      @include respond(m5){
        right: -15px;
      }
    }
  }
}

.b-portfolio__slider{
  .slick-arrow{
    top: 0;
    margin-top: -65px;

    &.slick-prev{
      left: -332px;

      @include respond(s0){
        left: 50px;
      }
      @include respond(m2){
        left: -60px;
      }
      @include respond(m3){
        left: -52px;
        margin-top: -4px;
      }
    }

    &.slick-next{
      right: -332px;

      @include respond(s0){
        right: 50px;
      }
      @include respond(m2){
        right: -60px;
      }
      @include respond(m3){
        right: -52px;
        margin-top: -4px;
      }
    }
  }
}

.b-testimonials__slider{
  .slick-arrow{

    @include respond(m1){
      height: 48px;
      width: 48px;
      border-width: 2px;

      svg{
        height: 24px;
      }
    }

    &.slick-prev{
      left: -62px;
      margin-top: -41px;

      @include respond(m1){
        left: 0;
        margin-top: -57px;
      }
    }

    &.slick-next{
      right: -62px;
      margin-top: -41px;

      @include respond(m1){
        right: 0;
        margin-top: -57px;
      }
    }
  }
}

.b-affiliations__slider{
  .slick-arrow{
    margin-top: -16px;

    &.slick-prev{
      left: -99px;

      @include respond(s0){
        left: 10px;
      }
    }

    &.slick-next{
      right: -99px;

      @include respond(s0){
        right: 10px;
      }
    }
  }
}

.slick-dots{
  position: relative;
  box-sizing:border-box;
  z-index: 900;
  font-size: 0;
  padding: 0px;
  height: 58px;
  width: 308px;
  @include flex;
  left: -192px;

  li{
    @include display_inline_block;
    position: relative;
    padding: 0;
    margin: 0 0 0 192px;
    height: 58px;
    width: 58px;
    top: 0;
  }
}
