@font-face {
  font-family: 'icomoon';
  src:  url('../../webfonts/icomoon.eot');
  src:  url('../../webfonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../webfonts/icomoon.ttf') format('truetype'),
    url('../../webfonts/icomoon.woff') format('woff'),
    url('../../webfonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-youtube:before {
  content: "\e900";
  color: #ccc;
}
.icon-social-vimeo:before {
  content: "\e901";
  color: #ccc;
}
.icon-social-twitter:before {
  content: "\e902";
  color: #ccc;
}
.icon-social-instagram:before {
  content: "\e903";
  color: #ccc;
}
.icon-social-facebook:before {
  content: "\e904";
  color: #ccc;
}
.icon-login:before {
  content: "\e905";
  color: #afafaf;
}
.icon-in:before {
  content: "\e906";
  color: #2897a0;
}
.icon-fb:before {
  content: "\e907";
  color: #3b5998;
}
